<template>
  <div id="form_masuk">
    <b-row align-h="between" align-v="center">
      <b-col sm="4" class="d-flex align-items-center mb-2 mb-sm-0">
        <b-button variant="outline-secondary" @click="$router.go(-1)">
          <feather-icon icon="ArrowLeftIcon" :style="{ cursor: 'pointer' }" />
          <span>Kembali</span>
        </b-button>
      </b-col>
    </b-row>
    <!-- <overlay-loading :loading="fetchLoading"> -->
    <form-wizard
      ref="wizard"
      color="#7367F0"
      title="Form Type 3"
      :subtitle="formTitle"
      layout="vertical"
      :finish-button-text="
        $router.currentRoute.params.id !== undefined
          ? 'Simpan Revisi'
          : 'Terbitkan Form'
      "
      back-button-text="Previous"
      :lazy="true"
      :before-change="() => true"
      class="vertical-steps steps-transparent mb-4"
      @on-complete="formSubmitted"
      @on-change="onTabChange"
    >
      <!-- <div
        slot="footer"
        scope="props"
      >
        <button @click="props.prev()">
          Prev as
        </button>
        <button>Cancel</button>
        <button @click="props.next()">
          Next as
        </button>
        <button>Cancel</button>
      </div> -->

      <!-- Delivery Route -->
      <tab-content
        :title="$t('Delivery Routes')"
        icon="feather icon-user"
        :lazy="true"
        :before-change="validationFormDeliveryRoute"
      >
        <validation-observer ref="deliveryRouteRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">
                {{ $t('Delivery Routes') }}
              </h5>
              <small class="text-muted"
                >{{ this.$t('Create Your ') }}{{ $t('Delivery Routes') }}</small
              >
            </b-col>
          </b-row>
          <!-- v-bind="cardAttrs"
              v-on="$listeners" -->
          <b-row>
            <b-col cols="12" class="mb-2">
              <b-card no-body class="py-1">
                <!-- @submit.prevent="repeateAgain" -->
                <b-form ref="formSegmen" class="repeater-form ml-2 mr-2">
                  <b-row>
                    <b-col md="12">
                      <b-form-group
                        label="Pilih Segmen"
                        label-for="vi-schedule-segmen"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Segmen"
                          vid="vi-schedule-segmen"
                          rules="required"
                        >
                          <v-select
                            id="vi-schedule-segmen"
                            v-model="form.schedule.segmen"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :reduce="(option) => option.title"
                            label="title"
                            :options="option.segmen"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card>
            </b-col>
            <b-col
              v-if="form.schedule.segmen"
              lg="6"
              md="12"
              cols="12"
              class="mb-2"
            >
              <b-card no-body class="py-1">
                <!-- :style="{height: trHeight}" -->
                <!-- @submit.prevent="repeateAgain" -->
                <b-form ref="formBongkar" class="repeater-form ml-2 mr-2">
                  <b-row>
                    <b-col md="12">
                      <b-form-group
                        label="Kegiatan"
                        label-for="vi-schedule-kegiatan-delivery"
                      >
                        <b-form-checkbox
                          v-model="form.schedule.keg_bongkar"
                          class="custom-control-warning"
                        >
                          Bongkar
                        </b-form-checkbox>
                      </b-form-group>
                    </b-col>

                    <b-col v-if="form.schedule.keg_bongkar" md="12">
                      <b-form-group
                        label="No Dokumen"
                        label-for="vi-bongkar-no_document"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="No Dokumen"
                          vid="vi-bongkar-no_document"
                          rules="required"
                        >
                          <b-form-input
                            id="vi-bongkar-no_document"
                            v-model="form.schedule.bongkar_no_doc"
                            placeholder="No Dokumen"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col v-if="form.schedule.keg_bongkar" md="12">
                      <b-form-group
                        label="Asal Barang"
                        label-for="vi-bongkar-asal_brg"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Asal Barang"
                          vid="vi-bongkar-asal_brg"
                          rules="required"
                        >
                          <b-form-input
                            id="vi-bongkar-asal_brg"
                            v-model="form.schedule.bongkar_asal"
                            v-uppercase
                            placeholder="Asal Barang"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- LOOPING FIELD -->
                    <b-row
                      v-for="(item, index) in form.schedule.itemBongkars"
                      v-if="form.schedule.keg_bongkar"
                      :id="item.fe_id"
                      :key="item.fe_id"
                      ref="rowBongkar"
                      class="repeater-form ml-1 mr-1"
                    >
                      <b-col cols="1">
                        <b-badge pill variant="info" class="mt-0">
                          {{ index + 1 }}
                        </b-badge>
                      </b-col>
                      <b-col cols="10">
                        <hr />
                      </b-col>
                      <b-col cols="1">
                        <!-- <b-button

                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"

                          > -->
                        <b-badge pill variant="danger">
                          <feather-icon
                            icon="XIcon"
                            :style="{ cursor: 'pointer' }"
                            @click="removeItemBongkar(index)"
                          />
                        </b-badge>

                        <!-- </b-button> -->
                      </b-col>

                      <b-col
                        v-if="form.schedule.keg_bongkar"
                        lg="12"
                        md="12"
                        cols="12"
                      >
                        <b-form-group
                          label="Jenis Barang"
                          label-for="vi-bongkar-jenis_brg"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Jenis Barang"
                            vid="vi-bongkar-jenis_brg"
                            rules="required"
                          >
                            <v-select
                              id="vi-bongkar-jenis_brg"
                              v-model="item.jenis_brg"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              :reduce="(option) => option.title"
                              label="title"
                              :options="option.jenis_brg"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col
                        v-if="form.schedule.keg_bongkar"
                        lg="6"
                        md="6"
                        cols="12"
                      >
                        <b-form-group
                          label="Jumlah Muatan"
                          label-for="vi-bongkar-jml_muatan"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Jumlah Muatan"
                            vid="vi-bongkar-jml_muatan"
                            rules="required"
                          >
                            <cleave
                              id="vi-bongkar-jml_muatan"
                              v-model="item.jml_muatan"
                              class="form-control"
                              :raw="true"
                              :options="options.number"
                              placeholder="Cth 1.000 (dot automaticaly )"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col
                        v-if="form.schedule.keg_bongkar"
                        lg="6"
                        md="6"
                        cols="12"
                      >
                        <b-form-group
                          label="Satuan"
                          label-for="vi-bongkar-satuan"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Satuan"
                            vid="vi-bongkar-satuan"
                            rules="required"
                          >
                            <v-select
                              id="vi-bongkar-satuan"
                              v-model="item.satuan"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              :reduce="(option) => option.title"
                              label="title"
                              :options="option.satuan"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Remove Button -->
                      <!-- <b-col
                        lg="5"
                        md="5"
                        cols="11"
                      >
                        <b-form-group
                          v-if="itemBongkars.length > 1"
                          label="Hapus"
                          label-for="vi-bongkar-hapus"
                        >
                          <b-button

                            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                            variant="outline-danger"
                            @click="removeItem(index)"
                          >
                            <feather-icon
                              icon="XIcon"
                              class="mr-25"
                            />

                          </b-button>
                        </b-form-group>
                      </b-col> -->
                    </b-row>
                    <!-- END OF LOOPING FIELD -->
                    <b-col
                      v-if="form.schedule.keg_bongkar"
                      lg="12"
                      md="12"
                      cols="12"
                    >
                      <b-button
                        size="md"
                        variant="outline-primary"
                        block
                        @click="repeateAgainBongkar"
                      >
                        <feather-icon icon="PlusIcon" class="mr-25" />
                        <span>Tambah Jenis Barang</span>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card>
            </b-col>
            <b-col
              v-if="form.schedule.segmen"
              lg="6"
              md="12"
              cols="12"
              class="mb-2"
            >
              <b-card no-body class="py-1">
                <!-- @submit.prevent="repeateAgain" -->
                <b-form ref="formMuat" class="repeater-form ml-2 mr-2">
                  <b-row>
                    <b-col md="12">
                      <b-form-group
                        label="Kegiatan"
                        label-for="vi-schedule-kegiatan-delivery"
                      >
                        <b-form-checkbox
                          v-model="form.schedule.keg_muat"
                          class="custom-control-warning"
                        >
                          Muat
                        </b-form-checkbox>
                      </b-form-group>
                    </b-col>
                    <b-col v-if="form.schedule.keg_muat" cols="12">
                      <b-form-group
                        label="No Dokumen"
                        label-for="vi-muat-no_document"
                      >
                        <!-- <validation-provider
                            #default="{ errors }"
                            name="No Dokumen"
                            vid="vi-muat-no_document"
                            rules="required"
                          > -->
                        <b-form-input
                          id="vi-muat-no_document"
                          v-model="form.schedule.muat_no_doc"
                          placeholder="No Dokumen ( Opsional )"
                        />
                        <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                        <!-- </validation-provider> -->
                      </b-form-group>
                    </b-col>
                    <b-col v-if="form.schedule.keg_muat" cols="12">
                      <b-form-group label="Tujuan" label-for="vi-muat-tujuan">
                        <validation-provider
                          #default="{ errors }"
                          name="Tujuan"
                          vid="vi-muat-tujuan"
                          rules="required"
                        >
                          <b-form-input
                            id="vi-muat-tujuan"
                            v-model="form.schedule.muat_tujuan"
                            v-uppercase
                            placeholder="Tujuan"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- LOOPING FIELD -->
                    <b-row
                      v-for="(item, index) in form.schedule.itemMuats"
                      v-if="form.schedule.keg_muat"
                      :id="item.fe_id"
                      :key="item.fe_id"
                      ref="rowMuat"
                      class="repeater-form ml-1 mr-1"
                    >
                      <b-col cols="1">
                        <b-badge pill variant="info" class="mt-0">
                          {{ index + 1 }}
                        </b-badge>
                      </b-col>
                      <b-col cols="10">
                        <hr />
                      </b-col>
                      <b-col cols="1">
                        <!-- <b-button

                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"

                          > -->
                        <b-badge pill variant="danger">
                          <feather-icon
                            icon="XIcon"
                            :style="{ cursor: 'pointer' }"
                            @click="removeItemMuat(index)"
                          />
                        </b-badge>

                        <!-- </b-button> -->
                      </b-col>
                      <b-col v-if="form.schedule.keg_muat" cols="12">
                        <b-form-group
                          label="Jenis Barang"
                          label-for="vi-muat-jenis_brg"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Jenis Barang"
                            vid="vi-muat-jenis_brg"
                            rules="required"
                          >
                            <v-select
                              id="vi-muat-jenis_brg"
                              v-model="item.jenis_brg"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              :reduce="(option) => option.title"
                              label="title"
                              :options="option.jenis_brg"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col
                        v-if="form.schedule.keg_muat"
                        lg="6"
                        md="6"
                        cols="12"
                      >
                        <b-form-group
                          label="Jumlah Muatan"
                          label-for="vi-muat-jml_muatan"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Jumlah Muatan"
                            vid="vi-muat-jml_muatan"
                            rules="required"
                          >
                            <cleave
                              id="vi-muat-jml_muatan"
                              v-model="item.jml_muatan"
                              class="form-control"
                              :raw="true"
                              :options="options.number"
                              placeholder="Cth 1.000 (dot automaticaly )"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col
                        v-if="form.schedule.keg_muat"
                        lg="6"
                        md="6"
                        cols="12"
                      >
                        <b-form-group label="Satuan" label-for="vi-muat-satuan">
                          <validation-provider
                            #default="{ errors }"
                            name="Satuan"
                            vid="vi-muat-satuan"
                            rules="required"
                          >
                            <v-select
                              id="vi-muat-satuan"
                              v-model="item.satuan"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              :reduce="(option) => option.title"
                              label="title"
                              :options="option.satuan"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <!-- END OF LOOPING FIELD -->
                    <b-col
                      v-if="form.schedule.keg_muat"
                      lg="12"
                      md="12"
                      cols="12"
                    >
                      <b-button
                        size="md"
                        variant="outline-primary"
                        block
                        @click="repeateAgainMuat"
                      >
                        <feather-icon icon="PlusIcon" class="mr-25" />
                        <span>Tambah Jenis Barang</span>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- Land Vehicle -->
      <tab-content
        :title="$t('Land Vehicles')"
        icon="feather icon-truck"
        :lazy="true"
        :before-change="validationFormVehicle"
      >
        <validation-observer ref="vehicleRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">
                {{ this.$t('Land Vehicles') }}
                <b-badge
                  href="#"
                  pill
                  :variant="statusSuggestionColor[dm_land_vehicleState]"
                >
                  <strong
                    ><span v-text="statusSuggestion[dm_land_vehicleState]"
                  /></strong>
                </b-badge>
              </h5>
              <small class="text-muted">
                {{ this.$t('Create Your ') }}{{ this.$t('Land Vehicles')
                }}{{ this.$t(' or Use data on already exist') }}.
              </small>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Plat Nomor Polisi"
                label-for="vi-dm_land_vehicle-plat_no_pol"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Plat Nomor Polisi"
                  rules="required"
                >
                  <vue-autosuggest
                    id="vi-dm_land_vehicle-plat_no_pol"
                    v-model="form.dm_land_vehicle.plat_no_pol"
                    v-uppercase
                    :suggestions="[
                      { data: form.dm_land_vehicle.dm_land_vehicles }
                    ]"
                    :get-suggestion-value="getSuggestionValue"
                    :input-props="{
                      id: 'autosuggest__input',
                      class: 'form-control',
                      placeholder: 'KT 1945 DR'
                    }"
                    @focus="
                      getSuggestionValueFocus(
                        $event,
                        'dm_land_vehicle',
                        'plat_no_pol'
                      )
                    "
                    @input="
                      searchForm(
                        $event,
                        'dm_land_vehicle/',
                        'dm_land_vehicle',
                        'plat_no_pol'
                      )
                    "
                  >
                    <template slot-scope="{ suggestion }">
                      <div class="d-flex align-items-center">
                        <div class="detail ml-50">
                          <b-card-text class="mb-0">
                            <strong>
                              <span
                                class="my-suggestion-item"
                                v-text="
                                  `${
                                    suggestion.item.plat_no_pol || ''
                                  }, Jenis: ${
                                    suggestion.item.type_vehicle || ''
                                  }`
                                "
                              />
                            </strong>
                          </b-card-text>
                          <small class="text-muted">
                            <strong
                              ><span
                                v-text="
                                  `${dateFormat(suggestion.item.created_at)}`
                                "
                            /></strong>
                          </small>
                        </div>
                      </div>
                    </template>
                    <template slot="before-suggestions">
                      <div class="ml-1 mt-1">
                        <b-badge
                          href="#"
                          pill
                          :variant="statusSuggestionColor[dm_land_vehicleState]"
                        >
                          <span
                            >
                            {{
                              statusSuggestionChoose[dm_land_vehicleState]
                            }}</span
                          >
                        </b-badge>
                      </div>
                    </template>
                  </vue-autosuggest>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <b-spinner
                    v-if="dm_land_vehicleStateLoading"
                    class="suggest-loading"
                    small
                    variant="primary"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Jenis Kendaraan"
                label-for="vi-dm_land_vehicle-type_vehicle"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Jenis Kendaraan"
                  rules="required"
                >
                  <v-select
                    id="vi-dm_land_vehicle-type_vehicle"
                    v-model="form.dm_land_vehicle.type_vehicle"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :reduce="(option) => option.title"
                    :disabled="!form.dm_land_vehicle.isNew"
                    :options="option.kendaraan"
                    :readonly="
                      $router.currentRoute.params.id !== undefined
                        ? false
                        : !form.dm_land_vehicle.isNew
                    "
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Created BY"
                label-for="vi-dm_land_vehicle-created_by"
              >
                <b-form-input
                  id="vi-dm_land_vehicle-created_by"
                  placeholder="Otomatis Terisi"
                  :value="form.dm_land_vehicle.user.full_name"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Phone of Created BY"
                label-for="vi-dm_land_vehicle-phone_of_created_by"
              >
                <b-form-input
                  id="vi-dm_land_vehicle-phone_of_created_by"
                  placeholder="Otomatis Terisi"
                  :value="form.dm_land_vehicle.user.phone"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Created AT"
                label-for="vi-dm_land_vehicle-created_at"
              >
                <b-form-input
                  id="vi-dm_land_vehicle-created_at"
                  placeholder="Otomatis Terisi"
                  :value="dateFormat(form.dm_land_vehicle.created_at)"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Updated AT" label-for="updated_at">
                <b-form-input
                  id="updated_at"
                  type="text"
                  readonly
                  :value="dateFormat(form.dm_land_vehicle.updated_at)"
                  placeholder="otomatis terisi"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- Driver -->
      <tab-content
        :title="$t('Person V1')"
        icon="feather icon-user"
        :lazy="true"
        :before-change="validationFormDriver"
      >
        <validation-observer ref="driverRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">
                Drivers
                <b-badge
                  href="#"
                  pill
                  :variant="statusSuggestionColor[dm_personalState]"
                >
                  <strong
                    ><span v-text="statusSuggestion[dm_personalState]"
                  /></strong>
                </b-badge>
              </h5>
              <small class="text-muted">
                {{ this.$t('Create Your ') }}Drivers
                {{ this.$t(' or Use data on already exist') }}. ( Bisa dicari
                dengan <code><strong>Nama Lengkap</strong></code> ataupun dengan
                <code><strong>Nomor KTP</strong></code> )
              </small>
            </b-col>
          </b-row>
          <div>
            <div>
              <b-form>
                <!-- Row Loop -->
                <b-row>
                  <!-- Item Name -->
                  <b-col md="3">
                    <b-form-group
                      label="Driver Name"
                      label-for="vi-dm_personal-full_name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Driver Name"
                        rules="required"
                      >
                        <vue-autosuggest
                          id="vi-dm_personal-full_name"
                          v-model="form.dm_personal.full_name"
                          v-uppercase
                          :suggestions="[
                            { data: form.dm_personal.dm_personals }
                          ]"
                          :get-suggestion-value="getSuggestionValue"
                          :input-props="{
                            id: 'autosuggest__input',
                            class: 'form-control',
                            placeholder: 'Full Name'
                          }"
                          @focus="
                            getSuggestionValueFocus(
                              $event,
                              'dm_personal',
                              'full_name'
                            )
                          "
                          @input="
                            searchForm(
                              $event,
                              'dm_personal/',
                              'dm_personal',
                              'full_name'
                            )
                          "
                        >
                          <template slot-scope="{ suggestion }">
                            <div class="d-flex align-items-center">
                              <div class="detail ml-50">
                                <b-card-text class="mb-0">
                                  <strong>
                                    <span
                                      class="my-suggestion-item"
                                      v-text="
                                        `${suggestion.item.full_name}, KTP: ${
                                          suggestion.item.no_ktp || ''
                                        }, HP: ${suggestion.item.phone || ''} `
                                      "
                                    />
                                  </strong>
                                </b-card-text>
                                <small class="text-muted">
                                  <strong
                                    ><span
                                      v-text="
                                        `${dateFormat(
                                          suggestion.item.created_at
                                        )}`
                                      "
                                  /></strong>
                                </small>
                              </div>
                            </div>
                          </template>
                          <template slot="before-suggestions">
                            <div class="ml-1 mt-1">
                              <b-badge
                                href="#"
                                pill
                                :variant="
                                  statusSuggestionColor[dm_personalState]
                                "
                              >
                                <span
                                  >
                                  {{
                                    statusSuggestionChoose[dm_personalState]
                                  }}</span
                                >
                              </b-badge>
                            </div>
                          </template>
                        </vue-autosuggest>
                        <small class="text-danger">{{ errors[0] }}</small>
                        <b-spinner
                          v-if="dm_personalStateLoading"
                          class="suggest-loading"
                          small
                          variant="primary"
                        />
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Cost -->
                  <b-col md="3">
                    <b-form-group label="No KTP" label-for="cost">
                      <validation-provider
                        #default="{ errors }"
                        name="No KTP"
                        :rules="{
                          required: true,
                          integer: true,
                          min: 16
                        }"
                      >
                        <cleave
                          id="cost"
                          v-model="form.dm_personal.no_ktp"
                          class="form-control"
                          :raw="true"
                          :options="options.noKTP"
                          :readonly="
                            $router.currentRoute.params.id !== undefined
                              ? false
                              : !form.dm_personal.isNew
                          "
                          placeholder="otomatis terisi"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="3">
                    <b-form-group label="No HP" label-for="quantity">
                      <validation-provider
                        #default="{ errors }"
                        name="No HP"
                        :rules="{
                          required: true,
                          min: 10,
                          max: 13
                        }"
                      >
                        <b-form-input
                          id="quantity"
                          v-model="form.dm_personal.phone"
                          type="number"
                          placeholder="otomatis terisi"
                          :readonly="
                            $router.currentRoute.params.id !== undefined
                              ? false
                              : !form.dm_personal.isNew
                          "
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Quantity -->

                  <b-col md="3">
                    <b-form-group
                      label="Phone of Created BY"
                      label-for="vi-dm_personal-phone_of_created_by"
                    >
                      <b-form-input
                        id="vi-dm_personal-phone_of_created_by"
                        placeholder="Otomatis Terisi"
                        :value="form.dm_personal.user.phone"
                        readonly
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Created BY" label-for="created_by">
                      <b-form-input
                        id="created_by"
                        type="text"
                        readonly
                        :value="form.dm_personal.user.full_name"
                        placeholder="otomatis terisi"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label="Created AT" label-for="created_at">
                      <b-form-input
                        id="created_at"
                        type="text"
                        :value="dateFormat(form.dm_personal.created_at)"
                        readonly
                        placeholder="otomatis terisi"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label="Updated AT" label-for="updated_at">
                      <b-form-input
                        id="updated_at"
                        type="text"
                        readonly
                        :value="dateFormat(form.dm_personal.updated_at)"
                        placeholder="otomatis terisi"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </div>
          </div>
        </validation-observer>
      </tab-content>

      <!-- Schedule -->
      <tab-content
        :title="$t('Schedules_LH')"
        icon="feather icon-calendar"
        :lazy="true"
        :before-change="validationFormSchedule"
      >
        <validation-observer ref="scheduleRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Schedules</h5>
              <small class="text-muted"
                >{{ this.$t('Create Your ') }}Schedules</small
              >
            </b-col>
            <b-col md="6">
              <b-form-group label="Schedule IN" label-for="vi-schedule_in">
                <validation-provider
                  #default="{ errors }"
                  name="Schedule IN"
                  rules="required"
                >
                  <flat-pickr
                    v-model="form.schedule.schedule_in"
                    class="form-control"
                    static="true"
                    :state="errors.length > 0 ? false : null"
                    :config="
                      $router.currentRoute.params.id !== undefined
                        ? dpconfigRevisi
                        : dpconfig
                    "
                    @on-change="onStartChange"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Schedule OUT" label-for="vi-schedule_out">
                <validation-provider
                  #default="{ errors }"
                  name="Schedule OUT"
                  rules="required"
                >
                  <flat-pickr
                    v-model="form.schedule.schedule_out"
                    class="form-control"
                    static="true"
                    :state="errors.length > 0 ? false : null"
                    :config="configs.end"
                    @on-change="onEndChange"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
    </form-wizard>
    <!-- </overlay-loading> -->
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { BCard, BRow, BCol, BFormGroup, BFormInput } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import OverlayLoading from '@views/components/OverlayLoading.vue'
import { VueAutosuggest } from 'vue-autosuggest'
import { heightTransition } from '@core/mixins/ui/transition'
import moment from 'moment'
import { Indonesian } from 'flatpickr/dist/l10n/id.js'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import VSelect from 'vue-select'
import router from '@/router'
import useJwt from '@/auth/jwt/useJwt'
import '@core/scss/vue/libs/vue-autosuggest.scss'
import { v4 as uuidv4 } from 'uuid'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    Indonesian,
    flatPickr,
    VueAutosuggest,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    OverlayLoading,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    VSelect,
    Cleave
  },
  directives: {
    Ripple
  },
  mixins: [heightTransition],
  data() {
    return {
      domain: process.env.VUE_APP_URL,
      userData: JSON.parse(localStorage.getItem('userData')),
      formTitle: '',
      dpconfig: {
        // default
        wrap: true,
        altInput: true,
        enableTime: true,
        time_24hr: false,
        dateFormat: 'Y-m-d H:i',
        locale: Indonesian,
        minDate: new Date(),
        maxDate: null
      },
      dpconfigRevisi: {
        wrap: true,
        altInput: true,
        enableTime: true,
        time_24hr: false,
        dateFormat: 'Y-m-d H:i',
        locale: Indonesian,
        minDate: null,
        maxDate: null
      },
      configs: {
        end: {
          wrap: true,
          altInput: true,
          enableTime: true,
          time_24hr: false,
          dateFormat: 'Y-m-d H:i',
          locale: Indonesian,
          minDate: null
        }
      },
      option: {
        kendaraan: [
          { title: 'TRUCK' },
          { title: 'LONGBED' },
          { title: 'TRAILER' },
          { title: 'MOBIL BOX' },
          { title: 'MOBIL' },
          { title: 'BIS' }
        ],
        segmen: [
          { title: 'ELNUSA LMP' },
          { title: 'MI (LMP)' },
          { title: 'DAS (Cementing)' },
          // { title: 'Facility (SLB)' },
          { title: 'RPI' },
          { title: 'ELNUSA (Cementing)' },
          { title: 'SAL' }
        ],
        satuan: [
          { title: 'M3' },
          { title: 'TON' },
          { title: 'BOX' },
          { title: 'SACKS' },
          { title: 'DRUM' },
          { title: 'BIG BAG' },
          { title: 'CAN' }
        ],
        jenis_brg: [
          { title: 'CURAH KERING' },
          { title: 'CURAH CAIR' },
          { title: 'EQUIPTMENT' },
          { title: 'LIMBAH B3' },
          { title: 'DLL' }
        ]
      },
      options: {
        creditCard: {
          creditCard: true
        },
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd']
        },
        phone: {
          phone: true,
          phoneRegionCode: 'US'
        },
        time: {
          time: true,
          timePattern: ['h', 'm', 's']
        },
        number: {
          // numeral: true,
          // numeralThousandsGroupStyle: 'thousand',
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '.'
          // suffix: 'LTR',
        },
        no_pol: {
          numeral: true,
          blocks: [4, 3, 3],
          uppercase: true
        },
        noKTP: {
          delimiters: [' ', ' ', ' '],
          blocks: [4, 4, 4, 4],
          uppercase: true
        },
        block: {
          blocks: [4, 3, 3],
          uppercase: true
        },
        delimiter: {
          delimiter: '·',
          blocks: [3, 3, 3],
          uppercase: true
        },
        customDelimiter: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
          uppercase: true
        },
        prefix: {
          phone: true,
          phoneRegionCode: 'ID'
        }
      },
      payload: [],
      form: {
        currentModel: '',
        currentFieldSuggest: '',
        currentFieldSuggestChild: null,

        dm_land_vehicle: {
          updateID: null,
          isEverRun: false,
          uri: 'dm_land_vehicle/',
          isNew: true,
          plat_no_pol: '',
          capacity: 0,
          user: {
            full_name: '',
            phone: ''
          },
          created_at: moment(),
          updated_at: moment(),
          dm_land_vehicles: []
        },
        dm_personal: {
          updateID: null,
          isEverRun: false,
          uri: 'dm_personal/',
          isNew: true,
          full_name: '',
          no_ktp: '',
          phone: '',
          user: {
            full_name: '',
            phone: ''
          },
          created_at: moment(),
          updated_at: moment(),
          dm_personals: []
        },
        // bongkar: {
        //   updateID: null,
        //   isEverRun: false,
        //   uri: 'bongkar/',
        //   isNew: true,
        //   no_document: '',
        //   jenis_brg: '',
        //   asal_brg: '',
        //   jml_muatan: '',
        //   satuan: '',
        //   user: {
        //     full_name: '',
        //     phone: '',
        //   },
        //   created_at: moment(),
        //   updated_at: moment(),
        //   bongkars: [],
        // },
        // muat: {
        //   updateID: null,
        //   isEverRun: false,
        //   uri: 'muat/',
        //   isNew: true,
        //   no_document: '',
        //   jenis_brg: '',
        //   tujuan: '',
        //   jml_muatan: '',
        //   satuan: '',
        //   user: {
        //     full_name: '',
        //     phone: '',
        //   },
        //   created_at: moment(),
        //   updated_at: moment(),
        //   muats: [],
        // },
        schedule: {
          uri: 'form3_land_vehicle/',
          schedule_in: null,
          schedule_out: null,

          keg_bongkar: false,
          keg_muat: false,

          bongkar_id: null,
          bongkar_no_doc: '',
          bongkar_asal: '',

          muat_id: null,
          muat_no_doc: '',
          muat_tujuan: '',

          itemBongkars: [
            {
              fe_id: 1
              // no_document: '',
              // asal_brg: '',
              // jenis_brg: '',
              // jml_muatan: 0,
              // satuan: 0,
            }
          ],
          nextItemBongkarID: 2,
          itemMuats: [
            {
              fe_id: 1
              // no_document: '',
              // tujuan: '',
              // jenis_brg: '',
              // jml_muatan: 0,
              // satuan: 0,
            }
          ],
          nextItemMuatID: 2,

          dm_land_vehicle_id: null,
          dm_personal_id: null,
          full_name: null, // dm_personal

          isSegmen: false,
          uuid: uuidv4()
        }
      },
      dataOverview: [],
      transportirOverview: [],
      landVehicleOverview: [],
      deliveryRoutesOverview: [],
      fetchLoading: false,
      selectedContry: 'select_value',
      selectedLanguage: 'nothing_selected',

      payloadNotification: {},

      // state suggestion
      dm_land_vehicleState: 0,
      dm_personalState: 0,
      statusSuggestion: {
        0: 'Membuat Bank Data Baru',
        1: 'Menggunakan Bank Data',
        2: 'Menggunakan Bank Data & Membuat Bank Data Baru'
      },
      statusSuggestionColor: {
        0: 'primary',
        1: 'warning',
        2: 'success'
      },
      statusSuggestionChoose: {
        0: 'Pilih Data yang pernah dibuat [ reuse-data ]',
        1: 'cancel [ reuse-data ] by click "space" then "backspace"',
        2: 'Pilih Data yang pernah dibuat [ reuse-data ]'
      },

      dm_land_vehicleStateLoading: false,
      dm_personalStateLoading: false
    }
  },
  mounted() {
    this.initTrHeight()
    this.formTitle = `📕 FORM TRUK MASUK UNTUK KEGIATAN ${
      this.userData.group?.name ?? '-'
    } (${this.userData.group?.description ?? '-'} )`
    const dataID = router.currentRoute.params.id
    if (dataID) {
      const { tabs } = this.$refs.wizard
      tabs.forEach((tab) => (tab.checked = true))
      this.$refs.wizard.maxStep = tabs.length
    }
  },
  created() {
    console.log('created:', this.form)
    window.addEventListener('resize', this.initTrHeight)
    if (
      this.$store.state.app.tokenNotification === '' ||
      this.$store.state.app.tokenNotification === null
    ) {
      this.$swal({
        title: 'Info, Notifikasi Bermasalah!',
        text: ' Kami telah secara otomatis mendeteksi pemberitahuan token sudah kedaluwarsa atau belum mengaktifkan izin notifikasi browser, Fix Notifikasi untuk memperbarui token dan coba kembali ke halaman ini.\njika Anda berada di browser Safari, coba lagi dengan browser Chrome/Firefox dengan perangkat Android atau Laptop/Komputer (OS windows/macOS)\n\n',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Fix (kedaluwarsa)',
        cancelButtonText: 'Abaikan Fitur Ini (iOS)',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(async (result) => {
        if (result.value) {
          this.$forceUpdate()
          if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
          this.timeoutDebounce = setTimeout(() => this.$router.go(-1), 300)
        } else {
          console.log('mengabaikan')
        }
      })
    }
    if (!this.$can('manage', 'all')) {
      const segmens = []
      this.$formSegmenSlb.forEach((value) => {
        console.log('my segmen:', value.key)
        if (this.$can(value.key, '')) {
          this.form.schedule.isSegmen = true

          segmens.push({
            title: value.key
          }) /* elnusa cementing membawahi elnusa lmp (can create and approve) */
          if (value.key === 'ELNUSA (Cementing)') {
            segmens.push({ title: 'ELNUSA LMP' })
          }
        }
      })
      this.form.schedule.segmen = segmens[0].title
      this.option.segmen = segmens
    }

    this.fetchData()
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    moment,
    onTabChange(prevTab, currentTab) {
      if (currentTab === this.$refs.wizard.maxStep - 1) {
        console.log('houya')
        this.dpconfigRevisi.minDate = this.form.schedule_in
        this.dpconfigRevisi.maxDate = this.form.schedule_out
      }
    },
    onStartChange(selectedDates, dateStr, instance) {
      this.configs.end.minDate = dateStr
    },
    onEndChange(selectedDates, dateStr, instance) {
      // 2022-08-22T13:34:00.000Z
      this.dpconfig.maxDate = dateStr
    },
    async sentNotification(isRevisiMsg) {
      await this.setPayloadNotification(isRevisiMsg)
      await useJwt.http
        .post('notification', this.payloadNotification)
        .then(() => {
          // console.log('data: ', data)
          this.fetchLoading = false
        })
        .catch((error) => {
          this.fetchLoading = false
          this.$refs.wizard.hideButtons = false
          console.log('data err: ', error)
          this.$swal({
            title: 'Error!',
            text: ` Form Masuk dan Keluar Berhasil dibuat tetapi ada kesalahan sistem untuk notification! ${error}. Ini tidak berdampak pada kerja sistem utama.`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          }).then(async () => {
            if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
            this.timeoutDebounce = setTimeout(() => this.$router.go(-1), 300)
          })
        })
    },
    async setPayloadNotification(isRevisiMsg) {
      const path = '/#/monitoring/jalur-darat/form/type-3'
      const route = this.domain + path

      // TODO: set from backend
      const group = this.userData.group?.name ?? null

      const mapType = {
        TEM: 'light-success',
        PATRA: 'light-warning',
        'PT PIL': 'light-info',
        SLB: 'light-danger',
        null: 'light-danger',
        undefined: 'light-danger'
      }
      const mapAction = {
        TEM: ` 📗 - ${isRevisiMsg}`,
        PATRA: ` 📔 - ${isRevisiMsg}`,
        'PT PIL': ` 📘 - ${isRevisiMsg}`,
        SLB: ` 📕 - ${isRevisiMsg}`,
        null: ` 📕 - ${isRevisiMsg}`
      }

      let type = ''
      let action = ''
      try {
        type = mapType[group]
        if (type === undefined) {
          type = 'light-danger'
        }
        action = mapAction[group]
        if (action === undefined) {
          action = ` 📕 - ${isRevisiMsg}`
        }
      } catch (err) {
        type = 'light-primary'
        action = ' 📗 - Published'
      }
      const title = this.userData.full_name

      this.payloadNotification = {
        form_id: '',
        registrationToken: this.$store.state.app.tokenNotification,
        title,
        url: route,
        form_type: 'T3',
        type,
        action,
        plat_no_pol: this.form.dm_land_vehicle.plat_no_pol,
        driver_name: this.form.dm_personal.full_name,
        is_read: false,
        tag: 'land_route'
      }
      // console.log('payload(1)', this.payloadNotification)
    },
    dateFormat(data) {
      return moment(data).format('D MMMM YYYY - hh:mm A')
    },
    getSuggestionValue(suggestion) {
      const model = this.form.currentModel
      // loading|state
      this[`${model}State`] = 1
      const field = this.form.currentFieldSuggest
      const fieldChild = this.form.currentFieldSuggestChild

      let result = ''
      if (fieldChild === '' || fieldChild === null || fieldChild === 'null') {
        const currentLoadData = this.form[`${model}`][`${model}s`]
        this.form[`${model}`] = {
          isEverRun: false, // default
          isNew: false,
          uri: this.form[`${model}`].uri,
          [`${model}s`]: currentLoadData,
          ...suggestion.item
        }
        console.log('suggestion change for:', this.form[`${model}`])

        result = suggestion.item[`${field}`]
      } else {
        const split = fieldChild.split('.')
        const currentLoadData =
          this.form[`${model}`][`${split[0]}`][`${split[0]}s`]
        this.form[`${model}`][`${split[0]}`] = {
          isEverRun: false, // default
          isNew: false,
          uri: this.form[`${model}`][`${split[0]}`].uri,
          [`${split[0]}s`]: currentLoadData,
          ...suggestion.item
        }
        this.form[`${model}`] = {
          ...this.form[`${model}`],
          [`${split[0]}_id`]: suggestion.item.id
        }
        console.log('suggestion change for 1:', this.form[`${model}`])
        console.log(
          'suggestion change for 2:',
          this.form[`${model}`][`${split[0]}`]
        )
        result = suggestion.item[`${split[1]}`]
      }
      return result
    },
    getSuggestionValueFocus(
      event,
      model,
      currentSuggest,
      childSuggesst = null
    ) {
      console.log(model)
      this.form.currentModel = model
      this.form.currentFieldSuggest = currentSuggest
      this.form.currentFieldSuggestChild = childSuggesst
    },
    searchForm(keyword, uri, model, currentSuggest, childSuggesst = null) {
      // loading|state
      if (this[`${model}State`] === 1) {
        this[`${model}State`] = 2
      }
      if (keyword) {
        clearTimeout(this.timeoutDebounce)
        this.timeoutDebounce = setTimeout(() => {
          // loading|state
          if (childSuggesst === null) {
            this[`${model}StateLoading`] = true
          } else {
            this.dm_personalStateLoading = true
          }
          useJwt.http
            .get(`${uri}`, { params: { keyword, single_search: true } })
            .then((response) => {
              if (response.data.total_items === 0) {
                if (childSuggesst === null) {
                  // no data
                  this.form[`${model}`].isNew = true
                  this.form[`${model}`][`${model}s`] = []

                  // auto filled
                  this.form[`${model}`].user.full_name = null
                  this.form[`${model}`].created_at = moment()
                  this.form[`${model}`].user.phone = null
                } else {
                  const split = childSuggesst.split('.')
                  this.form[`${model}`][`${split[0]}`].isNew = true
                  this.form[`${model}`][`${split[0]}`][`${split[0]}s`] = ''
                }
              } else if (childSuggesst === null) {
                this.form[`${model}`].isNew = true
                this.form[`${model}`][`${model}s`] = response.data[`${model}s`]
              } else {
                const split = childSuggesst.split('.')
                this.form[`${model}`][`${split[0]}`].isNew = true
                this.form[`${model}`][`${split[0]}`][`${split[0]}s`] =
                  response.data[`${split[0]}s`]
                // loading|state
                this.dm_personalStateLoading = false
              }
              // loading|state
              this[`${model}StateLoading`] = false
              this.dm_personalStateLoading = false
            })
            .catch((e) => {
              this.form[`${model}`].isNew = true
              this.form[`${model}`].user.full_name = null
              this.form[`${model}`][`${model}s`] = []
              // loading|state
              this[`${model}StateLoading`] = false
              this.dm_personalStateLoading = false
            })
        }, 300)
      }
    },
    repeateAgainBongkar() {
      this.form.schedule.itemBongkars.push({
        fe_id: (this.form.schedule.nextItemBongkarID +=
          this.form.schedule.nextItemBongkarID)
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.rowBongkar[0].offsetHeight)
      })
    },
    removeItemBongkar(index) {
      this.form.schedule.itemBongkars.splice(index, 1)
      this.trTrimHeight(this.$refs.rowBongkar[0].offsetHeight)
    },
    repeateAgainMuat() {
      console.log(this.form)
      this.form.schedule.itemMuats.push({
        fe_id: (this.form.schedule.nextItemMuatID +=
          this.form.schedule.nextItemMuatID)
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.rowMuat[0].offsetHeight)
      })
    },
    removeItemMuat(index) {
      this.form.schedule.itemMuats.splice(index, 1)
      this.trTrimHeight(this.$refs.rowMuat[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    // end
    async fetchData() {
      const uriAPI = 'form3_land_vehicle'
      const dataID = router.currentRoute.params.id
      if (dataID !== undefined) {
        this.fetchLoading = true

        await useJwt.http
          .get(`${uriAPI}/${dataID}`)
          .then((response) => {
            this.fetchLoading = false
            console.log('fetchData:', response.data.form3_land_vehicles)

            const dataOverview = response.data.form3_land_vehicles
            this.form.dm_land_vehicle = {
              ...dataOverview.dm_land_vehicle,
              updateID: dataOverview.dm_land_vehicle.id,
              isEverRun: false,
              uri: 'dm_land_vehicle/',
              isNew: false
            }
            this.form.dm_personal = {
              ...dataOverview.dm_personal,
              updateID: dataOverview.dm_personal.id,
              isEverRun: false,
              uri: 'dm_personal/',
              isNew: false
            }

            if (dataOverview.bongkar) {
              this.form.schedule.keg_bongkar = true
              this.form.bongkar = {
                ...dataOverview.bongkar,
                updateID: dataOverview.bongkar.id,
                isEverRun: false,
                uri: 'bongkar/',
                isNew: false
              }
              this.form.schedule.bongkar_id = dataOverview.bongkar.id
            }
            if (dataOverview.muat) {
              this.form.schedule.keg_muat = true
              this.form.muat = {
                ...dataOverview.muat,
                updateID: dataOverview.muat.id,
                isEverRun: false,
                uri: 'muat/',
                isNew: false
              }
              this.form.schedule.muat_id = dataOverview.muat.id
            }

            // payload
            this.form.schedule.schedule_in = dataOverview.schedule_in
            this.form.schedule.schedule_out = dataOverview.schedule_out

            this.form.schedule.dm_land_vehicle_id =
              dataOverview.dm_land_vehicle.id
            this.form.schedule.dm_personal_id = dataOverview.dm_personal.id
            this.form.schedule.full_name = dataOverview.dm_personal.full_name
            console.log('init payload:', this.form.schedule)
          })
          .catch((err) => {
            this.fetchLoading = false
            console.log(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Error load data ${err}`,
                icon: 'XIcon',
                variant: 'danger'
              }
            })
          })
          .finally(() => {
            this.fetchLoading = false
          })
      }
    },
    async getIDCreated(jwt, model) {
      let resultID
      await Promise.resolve(jwt).then(async (response) => {
        resultID = response.data[`${model}`].id
      })
      return resultID
    },
    async handlingDistributionData(model, strModel, updateID) {
      console.log(
        'action for ',
        model,
        ' and -> ',
        strModel,
        'updateID: ',
        updateID
      )

      if (updateID && model.isNew === false) {
        console.log('doing update:', updateID)
        const jwt = await useJwt.http.put(`${model.uri}${updateID}`, model)
        console.log('is new = false: ', strModel)
        this.form.schedule[`${strModel}_id`] = model.id
      } else if (model.isNew === true) {
        console.log('doing create')
        const jwt = await useJwt.http.post(model.uri, model)
        if (jwt) {
          const id = await this.getIDCreated(jwt, strModel)
          this.form.schedule[`${strModel}_id`] = id
        }
      } else {
        console.log(`doing use on exist data: ${strModel}_id`, model.id)
        this.form.schedule[`${strModel}_id`] = model.id
      }
    },
    async formSubmitted() {
      this.$refs.wizard.hideButtons = true
      const dataID = router.currentRoute.params.id
      const {
        dm_land_vehicle,
        dm_personal,
        schedule
        // bongkar, muat,
      } = this.form
      let breakSwalError = false

      // console.log('bongkar.isEverRun:', this.form.bongkar.isEverRun)
      // console.log('muat.isEverRun:', this.form.muat.isEverRun)
      console.log(
        'dm_land_vehicle.isEverRun:',
        this.form.dm_land_vehicle.isEverRun
      )
      console.log('dm_personal.isEverRun:', this.form.dm_personal.isEverRun)

      this.fetchLoading = true
      // if (schedule.keg_bongkar && bongkar.isEverRun === false) {
      //   await this.handlingDistributionData(bongkar, 'bongkar', bongkar.updateID).then(() => {
      //     bongkar.isEverRun = true
      //   }).catch(error => {
      //     this.$refs.wizard.hideButtons = false
      //     console.log('an error :', error)
      //     this.fetchLoading = false
      //     breakSwalError = true
      //     this.$swal({
      //       title: 'Error!',
      //       text: `${error.response.data.message ?? 'Data Tujuan Kegiatan Bongkar Error, Silahkan cek data kembali.'}`,
      //       icon: 'error',
      //       customClass: {
      //         confirmButton: 'btn btn-primary',
      //       },
      //       buttonsStyling: false,
      //     }).then(async () => {
      //       bongkar.isEverRun = false
      //     })
      //   })
      // }
      // if (schedule.keg_muat && muat.isEverRun === false) {
      //   await this.handlingDistributionData(muat, 'muat', muat.updateID).then(() => {
      //     muat.isEverRun = true
      //   }).catch(error => {
      //     this.$refs.wizard.hideButtons = false
      //     console.log('an error :', error.response.data)
      //     this.fetchLoading = false
      //     if (breakSwalError === false) {
      //       this.$swal({
      //         title: 'Error!',
      //         text: `${error.response.data.message ?? 'Data Tujuan Kegiatan Muat Error, Silahkan cek data kembali.'}`,
      //         icon: 'error',
      //         customClass: {
      //           confirmButton: 'btn btn-primary',
      //         },
      //         buttonsStyling: false,
      //       }).then(async () => {
      //         muat.isEverRun = false
      //       })
      //       breakSwalError = true
      //     }
      //   })
      // }

      if (dm_land_vehicle.isEverRun === false) {
        await this.handlingDistributionData(
          dm_land_vehicle,
          'dm_land_vehicle',
          dm_land_vehicle.updateID
        )
          .then(() => {
            dm_land_vehicle.isEverRun = true
          })
          .catch((error) => {
            this.$refs.wizard.hideButtons = false
            console.log('an error :', error.response.data)
            this.fetchLoading = false
            if (breakSwalError === false) {
              this.$swal({
                title: 'Error!',
                text: `${
                  error.response.data.message ??
                  'Data Kendaraan Darat, Silahkan cek data kembali.'
                }`,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
              }).then(async () => {
                dm_land_vehicle.isEverRun = false
              })
              breakSwalError = true
            }
          })
      }
      if (dm_personal.isEverRun === false) {
        await this.handlingDistributionData(
          dm_personal,
          'dm_personal',
          dm_personal.updateID
        )
          .then(() => {
            dm_personal.isEverRun = true
          })
          .catch((error) => {
            this.$refs.wizard.hideButtons = false
            console.log('an error :', error.response.data)
            this.fetchLoading = false
            if (breakSwalError === false) {
              this.$swal({
                title: 'Error!',
                text: `${
                  error.response.data.message ??
                  'Data Orang/Sopir Error, Silahkan cek data kembali.'
                }`,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
              }).then(async () => {
                dm_personal.isEverRun = false
              })
              breakSwalError = true
            }
          })
      }

      schedule.full_name = dm_personal.full_name
      schedule.plat_no_pol = dm_land_vehicle.plat_no_pol
      if (dataID) {
        console.log('payload update:', schedule)
        await useJwt.http
          .put(`${schedule.uri}${dataID}`, schedule)
          .then(async () => {
            this.fetchLoading = false
            this.$swal({
              title: 'Good job!',
              text: ' Berhasil merivisi Form Masuk & Keluar!',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false
            }).then(async () => {
              if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
              this.timeoutDebounce = setTimeout(() => this.$router.go(-1), 300)
            })
            await this.sentNotification('Revisi Data')
          })
          .catch((e) => {
            if (breakSwalError === false) {
              this.fetchLoading = false
              this.$refs.wizard.hideButtons = false
              this.$swal({
                title: 'Error!',
                text: ` Mohon maaf proses update error : ${e}`,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
              })
            }
          })
      } else {
        await useJwt.http
          .post(schedule.uri, schedule)
          .then(async () => {
            this.fetchLoading = false
            this.$swal({
              title: 'Good job!',
              text: ' Berhasil membuat Form Masuk & Keluar!',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false
            }).then(async () => {
              if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
              this.timeoutDebounce = setTimeout(() => this.$router.go(-1), 300)
            })
            await this.sentNotification('Published')
          })
          .catch((e) => {
            if (breakSwalError === false) {
              this.fetchLoading = false
              this.$refs.wizard.hideButtons = false
              this.$swal({
                title: 'Error!',
                text: ` Mohon maaf proses submit error : ${e}`,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
              })
            }
          })
      }
    },

    validationFormVehicle() {
      return new Promise((resolve, reject) => {
        this.$refs.vehicleRules.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormDriver() {
      return new Promise((resolve, reject) => {
        this.$refs.driverRules.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormDeliveryRoute() {
      return new Promise((resolve, reject) => {
        this.$refs.deliveryRouteRules.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSchedule() {
      return new Promise((resolve, reject) => {
        this.$refs.scheduleRules.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
#app {
  .vertical-layout {
    .app-content {
      .content-wrapper {
        .content-body {
          .vue-form-wizard {
            .wizard-card-footer {
              margin-left: 19.7em !important;
              @media (min-width: 400px) and (max-width: 900px) {
                margin-left: 0px !important;
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
#app {
  .app-content {
    .content-wrapper {
      .content-body {
        #form_masuk {
          .b-overlay-wrap {
            .vue-form-wizard {
              .wizard-navigation {
                .wizard-tab-content {
                  .row {
                    .suggest-loading {
                      // all of suggestion value
                      z-index: 99;
                      right: 2em;
                      top: 2.5em;
                      position: absolute; // inside form-input
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
